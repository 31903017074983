import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, Table, TableHead, TextField, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, IconButton, Select, MenuItem } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useAppDispatch } from '../../store/store';
import { createPrompt, getAllPrompts, updatePrompt } from '../../store/thunk/formThunk';
import { Delete } from '@mui/icons-material';
import ConfirmationModal from '../../components/ConfirmationModal';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const theme = useTheme();

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

export default function PromptList({ selectedCaseType }: any) {
    const dispatch = useAppDispatch()
    const [prompts, setPrompts] = useState<any>([]);
    const [initialPrompts, setInitialPrompts] = useState<any>([]);
    const [page, setPage] = useState<any>(0);
    const [modalState, setModalState] = useState<any>(null);
    const [rowsPerPage, setRowsPerPage] = useState<any>(5);
    const [prompt_column] = useState(["Prompt", "Inputs", "Outputs", 'Type', 'Actions']);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prompts?.length) : 0;
    const callGetAllPromptsApi = () => {
        dispatch(getAllPrompts()).then((res: any) => {
            setPrompts(res.payload?.data);
            setInitialPrompts(res.payload?.data)
        })
    }
    useEffect(() => {
        callGetAllPromptsApi()
    }, [])
    const onConfirm = () => {
        setModalState(null)
        const key = modalState.action === "DELETE_INPUT" ? 'inputs' : 'outputs';
        setPrompts((prevPrompts: any) => (
            prevPrompts.map((prompt: any) =>
                prompt.id === modalState.rowId
                    ? {
                        ...prompt,
                        [key]: prompt[key].filter((field: any) => field.id !== modalState.fieldId),
                    }
                    : prompt
            )
        ));
    }
    const onClose = () => {
        setModalState(null)
    }
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => { setPage(newPage); };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddNewPrompt = () => {
        setPrompts((prev: any) => [
            ...prev,
            {
                id: new Date().getTime(),
                text: '',
                inputs: [],
                outputs: []
            }
        ]);
    }

    const handleAddNewField = ({ rowId, action }: any) => {
        const key = action === 'ADD_INPUT' ? 'inputs' : 'outputs';
        setPrompts((prevPrompts: any) =>
            prevPrompts.map((prompt: any) =>
                prompt.id === rowId
                    ? {
                        ...prompt,
                        [key]: [
                            ...prompt[key],
                            { id: new Date().getTime(), promptId: rowId, value: '' }
                        ],
                    }
                    : prompt
            )
        );
    };


    const handleDeleteField = (payload: any) => {
        setModalState(payload);
    };

    const handleFieldChange = (
        { target: { name, value } }: React.ChangeEvent<HTMLInputElement>,
        payload: any,
    ) => {
        const key = payload.action === "INPUT" ? 'inputs' : 'outputs';
        setPrompts((prevPrompts: any) =>
            prevPrompts.map((prompt: any) =>
                prompt.id === payload.promptId
                    ? {
                        ...prompt,
                        [key]: prompt[key].map((field: any) => (
                            field.id === payload.id
                                ? { ...field, [name]: value }
                                : field
                        ))
                    }
                    : prompt
            )
        );
    };

    const handlePromptTitle = (e: any, id: number) => {
        setPrompts((prev: any) => (prev.map((row: any) =>
            row.id === id ? { ...row, text: e.target.value } : row
        )));
    };

    const handleSavePrompt = (id: number) => {
        const isExistingPrompt = initialPrompts.some((p: any) => p.id === id);
        const prompt = prompts?.find((p: any) => p.id === id);
        if (!isExistingPrompt) delete prompt.id
        prompt.inputs = prompt.inputs.map(({ id, ...rest }: any) => rest);
        prompt.outputs = prompt.outputs.map(({ id, ...rest }: any) => rest);

        if (isExistingPrompt) {
            dispatch(updatePrompt(prompt))
        } else {
            dispatch(createPrompt(prompt)).then(() => callGetAllPromptsApi())
        }
    }
    const handleSelectChange = ({ target: { name, value } }: any, rowId: number) => {
        setPrompts((prev: any) => prev.map((p: any) => p.id === rowId ? { ...p, [name]: value } : p))
    }
    return (
        <>
            <Box sx={{ float: 'right', pb: 2 }}>
                <Button variant="contained" onClick={handleAddNewPrompt}>Add New Prompt</Button>
            </Box>
            <Paper sx={{ boxShadow: 3, clear: 'both' }}>
                <TableContainer sx={{ height: "calc(100vh - 300px)" }}>
                    <Table sx={{ minWidth: 1000 }} aria-label="custom pagination table">
                        <TableHead >
                            <TableRow>
                                {prompt_column.map((column, i, arr) => (
                                    <TableCell align='center' sx={{ width: `${column !== "Actions" ? '30%' : ''}`, fontWeight: 700, borderRight: `${i + 1 !== arr.length ? '1px solid #ccc' : ''}` }}
                                    >{column}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? [...(prompts || [])].reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : [...(prompts || [])].reverse()
                            )?.map((row: any) => (
                                <TableRow key={row.id}>
                                    <TableCell sx={{ verticalAlign: 'top', borderRight: '1px solid #ccc' }}>
                                        <TextField
                                            fullWidth
                                            label="Prompt Title"
                                            multiline
                                            rows={4}
                                            value={row?.text}
                                            onChange={(e: any) => handlePromptTitle(e, row?.id)}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: 'top', borderRight: '1px solid #ccc' }}>
                                        {row.inputs?.map((input: any) => {
                                            return (
                                                <FormControl
                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, mb: 2 }}
                                                    key={input.id}
                                                >
                                                    <TextField
                                                        size="small"
                                                        label="Flow ID"
                                                        name='key'
                                                        value={input.key}
                                                        onChange={(e: any) => handleFieldChange(e, { id: input.id, promptId: input.promptId, action: 'INPUT' })}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        label="Title"
                                                        name='title'
                                                        value={input.title}
                                                        onChange={(e: any) => handleFieldChange(e, { id: input.id, promptId: input.promptId, action: 'INPUT' })}
                                                    />

                                                    <Button
                                                        sx={{ px: 0, minWidth: 'auto' }}
                                                        variant="text"
                                                        color="error"
                                                        onClick={() => handleDeleteField({ rowId: row.id, fieldId: input.id, action: 'DELETE_INPUT' })}
                                                    ><Delete /></Button>
                                                </FormControl>
                                            )
                                        })}
                                        <Button variant='contained' onClick={() => handleAddNewField({ rowId: row.id, action: 'ADD_INPUT' })} >Add New</Button>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: 'top', borderRight: '1px solid #ccc' }}>
                                        {row?.outputs?.map((output: any) => (
                                            <FormControl
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, mb: 2 }}
                                                key={output.id}
                                            >
                                                <TextField
                                                    size="small"
                                                    label="Flow ID"
                                                    name='key'
                                                    value={output.key}
                                                    onChange={(e: any) => handleFieldChange(e, { id: output.id, promptId: output.promptId, action: 'OUTPUT' })}
                                                />
                                                <TextField
                                                    size="small"
                                                    label="Title"
                                                    name='title'
                                                    value={output.title}
                                                    onChange={(e: any) => handleFieldChange(e, { id: output.id, promptId: output.promptId, action: 'OUTPUT' })}
                                                />

                                                <Button
                                                    sx={{ px: 0, minWidth: 'auto' }}
                                                    variant="text"
                                                    color="error"
                                                    onClick={() => handleDeleteField({ rowId: row.id, fieldId: output.id, action: 'DELETE_OUTPUT' })}
                                                >
                                                    <Delete />
                                                </Button>
                                            </FormControl>
                                        ))}
                                        <Button variant="contained" onClick={() => handleAddNewField({ rowId: row.id, action: 'ADD_OUTPUT' })}>
                                            Add New
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ borderRight: '1px solid #ccc' }}>
                                        <FormControl fullWidth>
                                            <Select
                                                name='type'
                                                value={row?.type}
                                                onChange={(e) => handleSelectChange(e, row.id)}
                                                sx={{ minWidth: 120 }}
                                            >
                                                <MenuItem value='Text'>Text</MenuItem>
                                                <MenuItem value='Document'>Document</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => handleSavePrompt(row.id)}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            sx={{ mt: 2 }}
                                            variant="contained"
                                            color="error"
                                            onClick={() => { }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    colSpan={3}
                    count={prompts?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    component="div"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <ConfirmationModal {...{
                title: 'Delete Field',
                content: 'delete this field?',
                isOpen: modalState,
                onConfirm,
                onClose
            }} />
        </>

    );
}
