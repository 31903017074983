import { Box, FormControl, MenuItem, Select, Tab, Tabs } from "@mui/material"
import { useEffect, useState } from "react";
import PromptList from "./PromptList";
import { getAllForms } from '../../store/thunk/formThunk';
import { useAppDispatch, useAppSelector } from "../../store/store";

const Prompts = () => {
    const dispatch = useAppDispatch();

    const { allForms } = useAppSelector(
        (state: any) => state?.formReducer
    );

    const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData") || "{}")
        : null;
    const [pageTab, setPageTab] = useState("1");
    const [formNameList, setFormNameList] = useState<any>([]);
    const [selectedCaseType, setSelectedCaseType] = useState();

    useEffect(() => {
        dispatch(getAllForms(userData?.billingEntityId));
    }, [userData?.billingEntityId]);

    useEffect(() => {
        const uniqueTitlesSet = new Set();
        const uniqueTitlesArray = allForms.filter((obj: any) => {
            if (!uniqueTitlesSet.has(obj.title)) {
                uniqueTitlesSet.add(obj.title);
                return true;
            }
            return false;
        });
        setSelectedCaseType(uniqueTitlesArray?.[0])
        setFormNameList(uniqueTitlesArray);

    }, [allForms]);

    const handlePageTab = (event: React.SyntheticEvent, newValue: string) => {
        setPageTab(newValue);
    };

    const handleCaseTypeChange = (e: any) => {
        setSelectedCaseType(e?.target?.value);
    }

    return (
        <>
            <Tabs
                value={pageTab}
                onChange={handlePageTab}
                aria-label="tabs"
                className="header-tabbar"
            >
                <Tab label="Text Prompts" value={"1"} />
                <Tab label="Document Prompts" value={"2"} />
            </Tabs>
            {pageTab === "1" && (
                <Box sx={{ p: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center", marginRight: 0, float: 'left', paddingBottom: 10 }}>
                        <label style={{ fontWeight: "700" }}>Case Type</label>
                        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                            <Select
                                labelId="demo-select-small-label1"
                                id="demo-select-small1"
                                value={selectedCaseType || formNameList[0]}
                                label="name"
                                name="flow"
                                onChange={(e: any) => handleCaseTypeChange(e)}
                            >
                                {formNameList?.map((list: any, index: any) => {
                                    return (
                                        <MenuItem key={index} value={list}>
                                            {list.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <PromptList {...{ selectedCaseType }} />
                </Box>
            )}
        </>
    )
}

export default Prompts